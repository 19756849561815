import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  backgroundImageRes: [],
  salePlanVisible: "2",
  profileTabValue: "info",
  signupData1: {},
  signupUser: {},
  currentUser: {},
  updateUser: {},
  sessionExpire: "",
  currentUnit: "Metric",
  isLoading: false,
  userToken: null,
  success: false,
  message: false,
  error: null,
};

// Thunk for check email avalibilty
export const checkEmailAvalibity = createAsyncThunk(
  "user/checkEmailAvalibity",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.emailAvailbility(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for check userSignup
export const checkUser = createAsyncThunk(
  "user/checkUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userAvalibility(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for check userSignup
export const signUp = createAsyncThunk(
  "user/signUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userSignup(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.loginProcess(data.password, data.userName);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user social login
export const checkSocialLogin = createAsyncThunk(
  "user/checkSocialLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.checkSocialLogin(data.id, data.social_network_type)
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user details
export const customerDeatail = createAsyncThunk(
  "user/customerDeatail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getCustomerDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user update profile details
export const updateDetails = createAsyncThunk(
  "user/updateDeatail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.updateCustomerDetails(data.requestData, data.userToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for backgrondImages
export const setBgImages = createAsyncThunk(
  "user/setBgImages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getHomeSliderImage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for sales plan visibility status
export const salesVisibility = createAsyncThunk(
  "user/salesVisibility",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.salesPlanVisibility();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


// manage user login session 
const userSessionLogin = (state, response) => {
  if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
    state.currentUser = response.data[0];
    state.updateUser = response.data[0];
    state.userToken = response.data[0].customer_auth_token;
    state.success = true;
  } else {
    state.currentUser = {};
    state.userToken = null;
    state.success = false;
  }
  state.isLoading = false
  state.profileTabValue = "info"
};

// manage user session expire
export const userSessionExpire = (state, response) => {
  if (response && response.settings.success == STATUS_CODES.INVALID_TOKEN) {
    state.sessionExpire = response.settings.message;
    state.currentUser = {};
    state.userToken = null;
  }
  state.isLoading = false
};


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.updateUser = {};
      state.isLoading = false;
      state.userToken = null;
      state.currentUnit = "Metric";
    },

    setCurrentUnit: (state, action) => {
      state.currentUnit = action.payload;
    },

    setProfileTabValue: (state, action) => {
      state.profileTabValue = action.payload;
    },
    signUpCurrentUser: (state, action) => {
      const response = action.payload;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.updateUser = response.data[0];
        state.currentUser = response.data[0];
        state.userToken = response.data[0].customer_auth_token;
        state.success = true;
      } else {
        state.currentUser = {};
        state.userToken = null;
        state.success = false;
      }
      state.isLoading = false
    },

    setSignupData1: (state, action) => {
      const { payload } = action;
      state.signupData1 = { ...state.signupData1, ...payload };
    },

    clearForm: (state) => {
      state.signupData1 = {};
    },

    updateSubcription: (state, action) => {
      // state.currentUser = {};
      const { payload } = action;
      state.currentUser = { ...state.currentUser, ...payload };
    },

  },
  extraReducers: (builder) => {
    // email signup
    builder.addCase(checkEmailAvalibity.pending, (state) => {
      // state.isLoading = true;
      state.error = null;
    });
    builder.addCase(checkEmailAvalibity.fulfilled, (state, action) => {
      const response = action.payload;
      state.signupUser = response && response.data;
      // state.isLoading = false;
    });
    builder.addCase(checkEmailAvalibity.rejected, (state, action) => {
      state.signupUser = false;
      // state.isLoading = false;
      state.error = action.payload.error;
      state.error = action.error.message;
    });

    // user login
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      userSessionLogin(state, response);
      state.isLoading = false;
    })
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message
    })

    // check socail user login
    builder.addCase(checkSocialLogin.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(checkSocialLogin.fulfilled, (state, action) => {
      const response = action.payload;
      userSessionLogin(state, response);
      state.isLoading = false;
    })
    builder.addCase(checkSocialLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message
    })

    // get customer detail
    builder.addCase(customerDeatail.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(customerDeatail.fulfilled, (state, action) => {
      const response = action.payload;
      state.updateUser = response && response.data[0];
      userSessionExpire(state, response);
      state.isLoading = false;
    })
    builder.addCase(customerDeatail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message
    })

    // update customer details
    builder.addCase(updateDetails.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(updateDetails.fulfilled, (state, action) => {
      const response = action.payload;
      userSessionExpire(state, response);
      state.isLoading = false;
      if (response && response.settings.success == STATUS_CODES.SUCCESS_API) {
        state.updateUser = response.settings.data[0];
        state.success = true;
      } else {
        state.updateUser = {};
        state.success = false;
      }
    })
    builder.addCase(updateDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    })

    // username signup
    builder.addCase(checkUser.pending, (state) => {
      // state.isLoading = true;
      state.error = null;
    });
    builder.addCase(checkUser.fulfilled, (state, action) => {
      const response = action.payload;
      // state.signupUser = response.data;
      state.isLoading = false;
    });
    builder.addCase(checkUser.rejected, (state, action) => {
      state.signupUser = false;
      state.isLoading = false;
      state.error = action.payload.error;
      state.error = action.error.message;
    });

    // final signup
    builder.addCase(signUp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      const response = action.payload;
      state.signupUser = response;
      state.isLoading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // update background image in home page
    builder.addCase(setBgImages.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(setBgImages.fulfilled, (state, action) => {
      const response = action.payload;
      state.backgroundImageRes = response && response.settings.data;
      state.isLoading = false;
    })
    builder.addCase(setBgImages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    })

    // sales plan visibility status
    builder.addCase(salesVisibility.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(salesVisibility.fulfilled, (state, action) => {
      const response = action.payload;
      state.salePlanVisible = response && response.settings.data[0];
      state.isLoading = false;
    })
    builder.addCase(salesVisibility.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message
    })
  },
});

export default userSlice.reducer;
export const { setSignupData1, clearForm, userLogoutClear, signUpCurrentUser, setCurrentUnit, updateSubcription, setProfileTabValue } = userSlice.actions;
