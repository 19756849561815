import { Container, Navbar } from "react-bootstrap";
import styles from "../HeaderSection/HeaderSection.module.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Images/white_logo.png";
import { useTranslation } from "react-i18next";
import profile from "../../assets/Images/picture.png";
import { useDispatch, useSelector } from "react-redux";
import { salesVisibility } from "../../store/slices/UserSlice";
import { useEffect } from "react";
import { STATUS_CODES } from "../../utils/StatusCode";

// -------------function for header section-----------
function HeaderSection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser, salePlanVisible } = useSelector((state) => state.user);

  // --------function for Global setting for sales plan visibility status api call--------
  useEffect(() => {
    dispatch(salesVisibility()).then((responsejson) => {
      const response = responsejson.payload;
    })
  }, [])

  return (
    <section className={styles.headerSection}>
      <Container className="containerWidthFix">
        <div className={styles.navBar}>
          <Navbar expand="md">
            <Navbar.Brand>
              <NavLink to="/">
                {Object.keys(currentUser).length == 0 ?
                  <div className={styles.logoImg}>
                    <img src={logo} alt="logo" />
                  </div> :
                  <div className={styles.logoImgs}>
                    <img src={profile} alt="logo" />
                  </div>}
              </NavLink>
            </Navbar.Brand>
          </Navbar>
          <div>
            {salePlanVisible && salePlanVisible.value == "1" ?
              <NavLink className={styles.customNavLink} to="/exclusive">{t("SALE")}</NavLink>
              : ""}

            <NavLink className={styles.customNavLink} to="/subscription">{t("SUBSCRIPTION")}</NavLink>
          </div>
        </div>
      </Container>
    </section>
  );
}
export default HeaderSection;